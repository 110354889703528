export default {
  data () {
    return {
      limit: 20,
      page: 1
    }
  },
  mounted () {
    let sessionInfo = sessionStorage.getItem('page') || '{}'
    sessionInfo = JSON.parse(sessionInfo)
    if (this.routeName === sessionInfo.pageName) {
      this.page = sessionInfo.page
      this.limit = sessionInfo.page * 20
    }
  },
  methods: {
    async scrollToElement () {
      if (process.browser) {
        const productId = this.$cookies.get('productId')
        if (productId) {
          const element = document.getElementById(productId)
          if (element) {
            element.scrollIntoView({
              block: 'center',
              inline: 'nearest',
              behavior: 'auto'
            })
            await this.$cookies.remove('productId')
            await sessionStorage.removeItem('productId')
          }
        }
      }
    }
  },
  watch: {
    page (val) {
      if (val) {
        const pageName = this.routeName
        // this.$router.push({ query: { ...this.$route.query, page: val } })
        sessionStorage.setItem('page', JSON.stringify({ page: val, pageName }))
      }
    }
  }
}
