export default {
  computed: {
    currentRoute () {
      return this.$route
    },
    routeHistory () {
      const routeHistory = localStorage.getItem('routeHistory')
      return routeHistory ? JSON.parse(routeHistory) : []
    },
    currentRouteIndex () {
      return this.routeHistory.indexOf(this.$route.fullPath)
    },
    totalRoutes () {
      return this.routeHistory.length
    }
  },
  methods: {
    navigatePrevious () {
      let routeHistory = localStorage.getItem('routeHistory') || '[]'
      routeHistory = JSON.parse(routeHistory)

      if (routeHistory.length >= 2) {
        this.$router.push(this.routeHistory[this.routeHistory.length - 2])
        this.routeHistory.pop()
        localStorage.setItem('routeHistory', JSON.stringify(this.routeHistory))
      }
    }
  },
  mounted () {
    if (process.client) {
      this.lastRouteEvent = window.onpopstate = () => {
        if (this.routeHistory.length > 1) {
          this.navigatePrevious()
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.lastRouteEvent)
  }
}
