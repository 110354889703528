<template>
  <div>
    <app-breadcrumb :router-links="brandcrumbs" />

    <section class="container general-rating-container">
      <div class="d-flex align-items-center">
        <figure class="brand-image flex-shrink-0 mb-0">
          <img
            v-lazy-load
            :data-src="sellerInfo.user_info.logo"
            class="w-100 h-100 brand-image__item"
            :alt="sellerInfo.user_info.company_name"
          >
        </figure>
        <div>
          <h3 class="brand-name text-uppercase">
            {{ sellerInfo.user_info.company_name }}
          </h3>
          <!-- <div class="d-flex align-items-center rate">
            <app-rate :stars="sellerInfo.user_info.stars" />
            <span class="rate__point">
              {{ Math.round(sellerInfo.user_info.stars) || 0 }}
            </span>
          </div> -->
        </div>
      </div>
    </section>
    <div class="custom-tab">
      <ul
        id="myTab"
        class="nav nav-tabs container border-0 justify-content-center justify-content-xl-end"
        role="tablist"
      >
        <li class="nav-item custom-tab__item" role="presentation">
          <button
            class="w-100 nav-link custom-tab__item__btn border-0 text-uppercase position-relative"
            aria-label="Products"
            :class="{ active: activeTab === 'home' }"
            @click="changeTab('home')"
          >
            {{ $t('filters.products') }}
          </button>
        </li>
        <li class="nav-item custom-tab__item" role="presentation">
          <button
            class="w-100 nav-link custom-tab__item__btn border-0 text-uppercase position-relative"
            aria-label="About store"
            :class="{ active: activeTab === 'profile' }"
            @click="changeTab('profile')"
          >
            {{ $t('filters.about_store') }}
          </button>
        </li>
      </ul>
    </div>

    <div id="myTabContent" class="tab-content">
      <div
        id="home"
        :class="{ 'active show': activeTab === 'home' }"
        class="tab-pane fade show"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <section class="container">
          <h1 class="general-title">
            {{ sellerInfo.user_info.company_name }}
          </h1>
        </section>

        <client-only>
          <app-cascader :filters="renderFiltersList" />

          <app-infinite-scroll
            class="d-grid categories-container container p-0"
            :isLoading="activeTab !== 'profile'"
            @loadMore="loadMore"
          >
            <card-product
              v-for="productItem of productLists"
              :id="productItem.id"
              :key="productItem.id"
              :product="productItem"
            />
          </app-infinite-scroll>
        </client-only>
      </div>
      <div
        id="profile"
        class="tab-pane fade"
        :class="{ 'active show': activeTab === 'profile' }"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <card-contact
          :seller="sellerInfo.user_info"
          :sellet-page="true"
        />
        <section class="contact-general-information">
          <article class="container">
            <h2 class="general-title">
              {{ sellerInfo.user_info.company_name }}
            </h2>
            <p class="contact-description">
              {{ description }}
            </p>
          </article>
        </section>
        <article class="container">
          <h2 class="general-title">
            {{ $t('filters.similar_stores') }}
          </h2>
          <div class="similar-items-container d-grid">
            <card-brand
              v-for="(brand, index) of replaceStoreId(sellerInfo.similar_stores)"
              :key="index"
              :brand-info="brand"
            />
          </div>
          <div class="text-center see-more container">
            <nuxt-link
              tag="button"
              :to="{ path: localePath(`/seller`) }"
              class="btn btn-outline shadow-none rounded-0 d-inline-flex align-items-center justify-content-center"
            >
              {{ $t('basic.showMore') }}
              <icon-arrow-small class="arrow-right d-md-none" />
            </nuxt-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cloneDeep from 'clone-deep'
import { CATEGORYS, GENDERS, METALS, SOUVENIR_TYPE, STONE_CUP_OPTIONS } from '~/core/utils/enums'
import { filters, Carat, souvenirFilters } from '~/static/mock/lists'
import LastRoute from '~/core/mixins/lastRoute'
import Pagination from '~/core/mixins/pagination'

export default {
  components: {
    AppInfiniteScroll: () => import('~/components/ui/app-infinite-scroll.vue'),
    CardBrand: () => import('~/components/cards/card-brand.vue'),
    CardProduct: () => import('~/components/cards/card-product.vue'),
    AppBreadcrumb: () => import('~/components/ui/app-breadcrumb.vue'),
    AppCascader: () => import('~/components/ui/app-cascader.vue'),
    CardContact: () => import('~/components/cards/card-contact.vue')
    // AppRate: () => import('~/components/ui/app-rate.vue')
  },
  mixins: [LastRoute, Pagination],
  async asyncData ({ params, store, i18n }) {
    const sellerInfo = await store.dispatch('user/aboutStore', params.id)
    const description = `${
      sellerInfo.user_info[`description_${i18n.locale}`] || sellerInfo.user_info.description
    }`
    return {
      sellerInfo, description
    }
  },
  data () {
    return {
      filters: null,
      brandcrumbs: [
        {
          to: this.localePath('/'),
          title: this.$t('basic.main')
        },
        {
          to: this.localePath('/seller'),
          title: this.$t('auth.seller')
        },
        {
          to: this.localePath(`/seller/${this.$route.params.id}`),
          title: this.$route.params.id,
          active: true
        }
      ],
      localActiveTab: null
    }
  },
  fetch () {
    this.brandcrumbs[this.brandcrumbs.length - 1].title =
      this.sellerInfo.user_info.company_name || ''
  },
  head () {
    return {
      title: `Gold Center| ${this.sellerInfo.user_info.company_name}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.sellerInfo.user_info.description
        },

        {
          hid: 'content',
          name: 'content',
          content: `<h1>Gold Center| ${this.sellerInfo.user_info.company_name}</h1>`
        },
        ...this.$socialMeta({
          url: `${process.env.VUE_APP_URL}${this.$route.path}`,
          title: `Gold Center| ${this.sellerInfo.user_info.company_name}`,
          site_name: 'Gold Center',
          description: this.sellerInfo.user_info.description,
          img: this.sellerInfo.user_info.logo,
          locale: this.$i18n.locale,
          twitter: '@Gold Center',
          twitter_card: 'summary_large_image'
        })
      ]
    }
  },

  computed: {
    ...mapState({
      productLists: state => state.brand.brandListInfo.catalog || [],
      totalCount: state => state.brand.brandListInfo.total_count,
      maxPrice: state => state.brand.brandListInfo.max_price || 1500,
      minPrice: state => state.brand.brandListInfo.min_price || 0,
      brands: state => state.brand.brandListInfo.brands || [],
      maxWeight: state => state.brand.brandListInfo.max_weight || 1000,
      minWeight: state => state.brand.brandListInfo.min_weight || 0
    }),
    renderFiltersList () {
      return (
        this.filters?.filter(
          e => e?.queryName !== 'market' && e?.queryName !== 'region'
        ) || []
      )
    },
    activeTab () {
      return !this.$isServer && this.localActiveTab && (sessionStorage.getItem('activeTab') || 'home')
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler () {
        if (
          `${this.sellerInfo.user_info[`description_${this.$i18n.locale}`]}`
        ) {
          this.description = `${
            this.sellerInfo.user_info[`description_${this.$i18n.locale}`]
          }`
        } else {
          this.description = `${this.sellerInfo.user_info.description}`
        }
      }
    },
    '$route.query': {
      immediate: false,
      async handler (to, from) {
        const toValue = cloneDeep(to)
        const fromValue = cloneDeep(from)
        delete toValue.page
        delete fromValue.page
        if (
          JSON.stringify(to) !== JSON.stringify(from) &&
          JSON.stringify(toValue) !== JSON.stringify(fromValue)
        ) {
          this.page = 1
          await this.getInformation(false)
        }
      }
    },
    filters: {
      immediate: false,
      handler (newFilters) {
        this.$store.commit('ui/setGlobalFilters', newFilters)
      }
    }
  },
  async mounted () {
    await this.getInformation(false)
    this.localActiveTab = !this.$isServer && (sessionStorage.getItem('activeTab') || 'home')
  },
  beforeDestroy () {
    if (this.routeName !== 'seller-id') {
      sessionStorage.removeItem('activeTab')
    }
  },
  methods: {
    ...mapActions({
      list: 'brand/list',
      getAboutStore: 'user/aboutStore'
    }),
    replaceStoreId (arr) {
      return arr.map((e) => {
        e.id = e.store_id
        return e
      })
    },
    changeTab (type) {
      this.localActiveTab = type
      sessionStorage.setItem('activeTab', type)
    },
    async getInformation (loadMore) {
      await this.get({ loadMore })
      await this.scrollToElement()
      this.limit = 20
      await this.getFilters()
    },
    filtersValidatorByCategory (category) {
      let cloneFilter

      if (category === 'souvenir') {
        cloneFilter = cloneDeep(souvenirFilters)
      } else if (category === 'jewelry-gold' || category === 'jewelry-silver') {
        cloneFilter = cloneDeep(souvenirFilters)

        cloneFilter.forEach((e) => {
          if (e.queryName === 'metal') {
            e.options = e.options.filter(e => e.value !== 'other')
          }
        })
      } else {
        cloneFilter = cloneDeep(filters)
      }

      cloneFilter = this.filterMetalType(cloneFilter)

      cloneFilter.forEach((e) => {
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }
        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })

      return cloneFilter.filter(e => e?.access?.includes(category))
    },
    filtersValidatorByMetal (category, metal) {
      let cloneFilter
      if (category === 'souvenir') {
        cloneFilter = cloneDeep(souvenirFilters)
      } else {
        cloneFilter = cloneDeep(filters)
      }
      cloneFilter = cloneFilter.filter(elem => elem.queryName !== 'metal')

      const type = this.$route.params.type
      cloneFilter.forEach((e) => {
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }
        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })
      cloneFilter = cloneFilter.filter((elem) => {
        if (elem.options) {
          elem.options = elem.options.filter(
            subElem =>
              subElem.accessMetal.includes(metal) &&
              subElem.access.includes(category)
          )
        }
        return (
          (elem.access.includes(category) && elem.options?.length) ||
          elem.type === 'slider'
        )
      })
      if (type === 'wed_ring' || type === 'eng_ring') {
        cloneFilter = cloneFilter.filter(e => e.queryName !== 'gemstone')
      }
      cloneFilter = this.filterMetalType(cloneFilter)
      cloneFilter = this.userTypeFilterSeller(cloneFilter)

      if (this.showAditionalFilterDiamond('none')) {
        const exception = [
          'stoneCarat',
          'stoneCertificate',
          'stoneColor',
          'stone_clarity'
        ]
        return cloneFilter.filter(e => !exception.includes(e.queryName))
      }

      return cloneFilter
    },
    filtersValidatorByGender (category, metal, gender) {
      let cloneFilter
      if (category === 'souvenir') {
        cloneFilter = cloneDeep(souvenirFilters)
      } else {
        cloneFilter = cloneDeep(filters)
      }
      cloneFilter = cloneFilter.filter(elem => elem.queryName !== 'metal')
      const type = this.$route.params.type
      cloneFilter = this.filterMetalType(cloneFilter)
      cloneFilter = this.userTypeFilterSeller(cloneFilter)
      cloneFilter.forEach((e) => {
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }
        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })
      cloneFilter = cloneFilter.filter((elem) => {
        if (elem.options) {
          elem.options = elem.options.filter(
            subElem =>
              subElem.accessMetal.includes(metal) &&
              subElem.accessGender.includes(gender) &&
              subElem.access.includes(category)
          )
        }
        return (
          (elem.access.includes(category) && elem.options?.length) ||
          elem.type === 'slider'
        )
      })
      if (type === 'wed_ring' || type === 'eng_ring') {
        cloneFilter = cloneFilter.filter(e => e.queryName !== 'gemstone')
      }
      if (gender === 'boy' || gender === 'girl') {
        const exception = [
          'stoneCarat',
          'stoneCertificate',
          'stoneColor',
          'stone_clarity',
          'stoneCut'
        ]
        return cloneFilter.filter((e) => {
          if (e.queryName === 'stoneCut') {
            e.options = STONE_CUP_OPTIONS
          }
          return !exception.includes(e.queryName)
        })
      }

      if (this.showAditionalFilterDiamond('none')) {
        const exception = [
          'stoneCarat',
          'stoneCertificate',
          'stoneColor',
          'stone_clarity'
        ]
        return cloneFilter.filter(e => !exception.includes(e.queryName))
      }

      return cloneFilter
    },
    filtersValidatorBySouvenir () {
      const cloneFilter = cloneDeep(souvenirFilters)
      cloneFilter.forEach((e) => {
        if (e.queryName === 'store') {
          const brandOptions = this.brands.map(brand => ({
            title: brand.name,
            value: brand.id,
            access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
            accessGender: [
              GENDERS.MEN,
              GENDERS.WOMEN,
              GENDERS.BOY,
              GENDERS.GIRL
            ]
          }))
          e.options = brandOptions
        }
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }
        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })
      const filtredData = cloneFilter.filter((elem) => {
        return elem.queryName !== 'fineness' || elem.queryName === 'price'
      })
      if (this.showAditionalFilter(SOUVENIR_TYPE.COIN)) {
        return filtredData.filter((e) => {
          if (e.queryName === 'weight') {
            e.type = 'slider'
          }
          return e.queryName !== 'barsName'
        })
      }
      if (this.showAditionalFilter(SOUVENIR_TYPE.BARS)) {
        return filtredData.filter((e) => {
          return e.queryName !== 'country'
        })
      }
      if (this.showAditionalFilter(SOUVENIR_TYPE.ANTIQUE)) {
        return filtredData.filter(
          e =>
            e.queryName !== 'barsName' &&
            e.queryName !== 'weight' &&
            e.queryName !== 'fineness' &&
            e.queryName !== 'country'
        )
      }
      return filtredData.filter(
        e => e.queryName !== 'country' && e.queryName !== 'barsName'
      )
    },
    filtersValidatorBySouvenirMetal (metal) {
      const cloneFilter = cloneDeep(souvenirFilters)
      cloneFilter.forEach((e, index) => {
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }
        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })
      let filtredData = cloneFilter.filter((elem) => {
        if (elem.options) {
          const options = elem.options.filter((subElem) => {
            return subElem.accessMetal.includes(metal)
          })
          elem.options = options
        }
        return (
          (elem && elem?.options?.length) ||
          elem.queryName === 'price' ||
          elem.queryName === 'weight'
        )
      })
      if (this.showAditionalFilter(SOUVENIR_TYPE.COIN)) {
        return (filtredData = filtredData.filter((e) => {
          if (e.queryName === 'weight') {
            e.type = 'slider'
          }
          return e.queryName !== 'barsName'
        }))
      }
      if (this.showAditionalFilter(SOUVENIR_TYPE.BARS)) {
        return (filtredData = filtredData.filter((e) => {
          if (e.queryName === 'fineness') {
            e.options = [
              {
                title: 'Gold | 999.9',
                value: 'g999.9',
                accessMetal: [METALS.GOLD]
              }
            ]
          }
          return e.queryName !== 'country'
        }))
      }
      if (this.showAditionalFilter(SOUVENIR_TYPE.ANTIQUE)) {
        return (filtredData = filtredData.filter(
          e =>
            e.queryName !== 'barsName' &&
            e.queryName !== 'fineness' &&
            e.queryName !== 'weight' &&
            e.queryName !== 'country'
        ))
      }
      return filtredData.filter(
        e => e.queryName !== 'country' && e.queryName !== 'barsName'
      )
    },
    filtersValidatorByWatches (category) {
      let cloneFilter = cloneDeep(filters)
      cloneFilter = this.filterMetalType(cloneFilter).filter(e => e.queryName !== 'type')
      cloneFilter.forEach((elem) => {
        if (elem.queryName === 'brand') {
          const brandOptions = this.brands.map(brand => ({
            title: brand.name,
            value: brand.id,
            access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
            accessGender: [
              GENDERS.MEN,
              GENDERS.WOMEN,
              GENDERS.BOY,
              GENDERS.GIRL
            ]
          }))
          elem.options = brandOptions
        }
        if (elem.queryName === 'weight') {
          elem.maxValue = this.maxWeight
          elem.minValue = this.minWeight
        }
        if (elem.id === 1) {
          elem.maxValue = this.maxPrice
          elem.minValue = this.minPrice
        }
        if (elem.options) {
          const options = elem.options.filter(subElem =>
            subElem.access.includes(CATEGORYS.WATCHES)
          )
          elem.options = options
        }
        if (elem.queryName === 'stoneCut') {
          elem.options = STONE_CUP_OPTIONS
        }
      })
      const exception = [
        'slider',
        'fineness',
        'stoneCarat',
        'stoneCertificate',
        'stoneColor',
        'stone_clarity',
        'stoneCut'
      ]
      return cloneFilter.filter(e => !exception.includes(e.queryName))
    },
    filtersValidatorByWatchesMetal (category, metal) {
      let cloneFilter = cloneDeep(filters)
      cloneFilter = this.filterMetalType(cloneFilter).filter(e => e.queryName !== 'type')
      cloneFilter.forEach((e) => {
        if (e.queryName === 'weight') {
          e.maxValue = this.maxWeight
          e.minValue = this.minWeight
        }

        if (e.id === 1) {
          e.maxValue = this.maxPrice
          e.minValue = this.minPrice
        }
      })
      cloneFilter = cloneFilter.filter((elem) => {
        if (elem.options) {
          const options = elem.options.filter(subElem =>
            subElem.accessMetal.includes(metal)
          )
          elem.options = options.filter(subElem =>
            subElem.access.includes(category)
          )
        }
        if (elem.queryName === 'stoneCut' && metal !== METALS.SILVER) {
          elem.options = STONE_CUP_OPTIONS
        }

        return (
          (elem.access.includes(category) && elem.options?.length) ||
          elem.type === 'slider'
        )
      })

      if (metal === 'other') {
        cloneFilter = cloneFilter.filter(
          e => e.queryName !== 'fineness' && e.queryName !== 'weight'
        )
      }

      const exception = [
        'stoneCarat',
        'stoneCertificate',
        'stoneColor',
        'stone_clarity',
        'stoneCut'
      ]
      return cloneFilter.filter(e => !exception.includes(e.queryName))
    },
    get ({ loadMore }) {
      const { id } = this.$route.params
      const carats = []
      const offset =
        this.limit > 20 ? 0 : +this.page > 1 ? (this.page - 1) * this.limit : 0
      const body = {
        id,
        query: {
          limit: this.limit,
          offset
        },
        loadMore
      }
      for (const key in this.$route.query) {
        if (Array.isArray(this.$route.query[key])) {
          if (key === 'stoneCarat') {
            const queryQaratValue = cloneDeep(this.$route.query[key])
            queryQaratValue.forEach(e =>
              Carat.forEach((carat) => {
                if (e === carat.value) {
                  carats.push(carat.id)
                }
              })
            )
          }
          body.query[key] = this.$route.query[key].filter(e => e).join(',')
        } else {
          if (key === 'stoneCarat') {
            const caratValue = cloneDeep(this.$route.query[key])
            const felem = Carat.find(e => e.value === caratValue)
            carats.push(felem.id)
          }
          body.query[key] = this.$route.query[key]
        }
      }
      if (carats.length) {
        body.query.stoneCarat = carats
      }
      if (body.query.category === 'jewelry-gold') {
        body.query.category = 'jewelry'
        body.query.metal = 'gold'
      }
      if (body.query.category === 'jewelry-silver') {
        body.query.category = 'jewelry'
        body.query.metal = 'silver'
      }
      return this.list(body)
    },
    async getFilters () {
      this.filters = []
      const category = (await this.$route.query?.category) || ''
      const metal = (await this.$route.query?.metal) || ''
      const gender = (await this.$route.query?.gender) || ''
      const categoryInfo = {
        title: 'menu.category',
        type: 'radio',
        queryName: 'category',
        key: 'category',
        id: 'category',
        options: [
          // {
          //   title: 'menu.jewelry',
          //   value: 'jewelry',
          //   accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
          // },
          // {
          //   title: 'menu.watches',
          //   value: 'watches',
          //   accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
          // },
          // {
          //   title: 'menu.souvenir',
          //   value: 'souvenir',
          //   accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
          // }
          {
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
            value: 'jewelry-gold',
            title: 'menu.gold_jewelry'
          },
          {
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
            value: 'jewelry-silver',
            title: 'menu.silver_jewelry'
          },
          {
            title: 'menu.watches',
            value: 'watches',
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
          },
          {
            title: 'menu.souvenir',
            value: 'souvenir',
            accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
          }
        ]
      }
      if (
        this.$route.query?.userType &&
        this.$route.query?.userType !== 'seller'
      ) {
        categoryInfo.options = categoryInfo.options.filter(
          felem => felem.value !== 'souvenir'
        )
      }

      if (
        this.$route.query?.userType === 'manufacturer' &&
        this.$route.query?.conditional === 'mounting'
      ) {
        categoryInfo.options = categoryInfo.options.filter(
          e => e.value === 'jewelry-gold' || e.value === 'jewelry-silver'
        )
      }

      if (category === 'souvenir' && metal) {
        this.filters = [
          categoryInfo,
          ...this.filtersValidatorBySouvenirMetal(metal)
        ]
        return
      }

      if (category === 'souvenir') {
        this.filters = [categoryInfo, ...this.filtersValidatorBySouvenir()]
        return
      }

      if (category === 'watches' && metal) {
        this.filters = [
          categoryInfo,
          ...this.filtersValidatorByWatchesMetal(category, metal)
        ]
        return
      }

      if (category === 'watches') {
        this.filters = [
          categoryInfo,
          ...this.filtersValidatorByWatches(category)
        ]
        return
      }

      if (this.$route.query.category) {
        const [categoryJewelry, metalJewelry] =
          this.$route.query.category.split('-')

        if (categoryJewelry && metalJewelry && gender) {
          this.filters = [
            categoryInfo,
            ...this.filtersValidatorByGender(
              categoryJewelry,
              metalJewelry,
              gender
            )
          ]

          return
        }

        if (categoryJewelry && metalJewelry) {
          this.filters = [
            categoryInfo,
            ...this.filtersValidatorByMetal(categoryJewelry, metalJewelry)
          ]
          return
        }

        if (categoryJewelry) {
          this.filters = [
            categoryInfo,
            ...this.filtersValidatorByCategory(categoryJewelry)
          ]
          return
        }
      }

      this.filters = [categoryInfo]
    },
    async loadMore () {
      if (this.showLoadMore() || !this.productLists.length) {
        return
      }
      this.page += 1
      await this.get({ loadMore: true })
      this.limit = 20
    },
    showAditionalFilterDiamond (val) {
      const type = this.$route.query.stoneCut || []
      return type.includes(val)
    },
    showAditionalFilter (val) {
      const type = this.$route.query.type || ''
      return type === val
    },
    showLoadMore () {
      return this.totalCount === this.productLists?.length
    }
  }
}
</script>

<style scoped>
.tab-content{
  margin-bottom: 34px;
}
</style>
